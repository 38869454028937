/**
 * Design: https://marvelapp.com/prototype/5edj454/screen/73348623
 * Example usage:
 *
 * 
    h1 {
        color:$clr-text;
        line-height:$lh-base; 
        font-size:$fs-h1;  
    }
 *
 */
 @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,500;1,700&display=swap');
 
$ff-base:               font-family;

//Font size
$fs-base:               16px;
$fs-h1:                 2.5rem;       //Headline 1
$fs-h2:                 2rem;    //Headline 2
$fs-h3:                 1.25rem;       //Headline 3
$fs-h4:                 1.125rem;   //Headline 4
$fs-h5:                 1rem;    //Headline 5
$fs-large:              1.125rem; // large subline
$fs-default:            1rem;       //interface: default text, input 
$fs-milli:              0.875rem;      //interface: label, ....                  
$fs-micro:              0.75rem;       //interface: helperText, listcaption,..

//Font Weight
$fw-base: 400;
$fw-bold: 700;

//Line Height
$lh-base:               1.5; //all


//Letter spacing
$ls-base:               0;
$ls-micro:              0.01rem;


