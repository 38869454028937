/**
 * Design: https://marvelapp.com/prototype/5edj454/screen/73348656
 * Example usage:
 *
 * 
    h1,h2,h3,h4,h5,h6{
        color:$clr-misc-primary;
    }
    .MuiButton-containedPrimary {
        background-color:   $clr-primary;
        color:$clr-primary-contrast;
    }
 *
 */


/* -- Colors
---------------------------------------------------------*/

//Miscellaneous
$clr-misc-white:        #ffffff;
$clr-misc-primary:      #333333;
$clr-misc-secondary:    #8A8A8A;
$clr-misc-background:   #f7f7f7;
$clr-misc-divider:      rgba(0, 0, 0, 0.12);


//Primary
$clr-primary:             #52A7F0;
$clr-primary-dark:         darken($clr-primary,16%);
$clr-primary-light:        lighten($clr-primary,40%);
$clr-primary-contrast:     $clr-misc-white;
$clr-primary-background:   rgba( $clr-primary, 0.12);

//Secondary
$clr-secondary:           #FC4F21;
$clr-secondary-dark:       darken($clr-secondary,16%);
$clr-secondary-light:      lighten($clr-secondary,40%);
$clr-secondary-contrast:   $clr-misc-white;
$clr-secondary-background: rgba( $clr-secondary, 0.12);

//Background
$clr-background:         #f7f7f7f7;

//Warning
$clr-warning:        #FF8800;
$clr-warning-dark:        darken($clr-warning,16%);
$clr-warning-light:       lighten($clr-warning,40%);
$clr-warning-contrast:    $clr-misc-white;
$clr-warning-background:  rgba( $clr-warning, 0.12);

//Succes
$clr-success:        #72DE75;
$clr-success-dark:       darken($clr-success,16%);
$clr-success-light:      lighten($clr-success,24%);
$clr-success-contrast:   $clr-misc-white;
$clr-success-background: rgba( $clr-success, 0.12);

//Info
$clr-info:           #00A6FF;
$clr-info-dark:           darken($clr-info,16%);
$clr-info-light:          lighten($clr-info,40%);
$clr-info-contrast:       $clr-misc-white;
$clr-info-background:     rgba( $clr-info, 0.12);

//Error
$clr-error:           #FF0000;
$clr-error-dark:           darken($clr-error,16%);
$clr-error-light:          lighten($clr-error,40%);
$clr-error-contrast:       $clr-misc-white;
$clr-error-background:     rgba( $clr-error, 0.12);



//Error
$clr-grey:           #333333;
$clr-grey-800:        lighten($clr-grey,10%);
$clr-grey-700:        lighten($clr-grey,20%);
$clr-grey-600:        lighten($clr-grey,30%);
$clr-grey-500:        lighten($clr-grey,40%);
$clr-grey-400:        lighten($clr-grey,50%);
$clr-grey-300:        lighten($clr-grey,60%);
$clr-grey-200:        lighten($clr-grey,70%);
$clr-grey-100:        lighten($clr-grey,80%);