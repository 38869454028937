@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import '../styles/variables/colors';
@import '../styles/variables/typography';
@import '../styles/variables/shadows';



/* -- Typography
---------------------------------------------------------*/
.colorText{color:$clr-misc-primary;}
.colorSecondary{color:$clr-misc-secondary;}


*{
    margin:0;
    padding:0;
    
}

.color{
    &--primary{
       background-color:$clr-primary;   
    }
    &--secondary{
        background-color:$clr-secondary; 
    }
}

body{
    background-color:$clr-misc-background;
    font-family: 'Poppins', sans-serif;
    line-height:1.5;
}


header#topbar{
    position:absolute;
    padding:24px;
    z-index:1;

}
#banner{
    position:relative;
    padding-top:128px;
    background-color:$clr-secondary;
    background: rgb(252,79,33);
    background: linear-gradient(225deg, rgba(255,142,29,1)  0%,    rgba(252,79,33,1)  100%);
    color:$clr-misc-white;

    .images{
        justify-content: center;
        position:relative;

        #meet_miek{
            z-index:1;
            position:absolute;
            left:25%;
            transition:all ease-in 0s;

        }
        .sign{
            position:absolute;
            right:40px;
            top:-15%;
            opacity:0.12;
        }
    
        p{
            margin-bottom:40px;
        }
    }
}
#values{
    .title_bar{
        padding-left:0;
    }
    div{
       
        box-sizing:border-box;
    }
}
.testimonial{
   
    color:$clr-misc-white;
    position:relative;;
    overflow:hidden;
    p{
        margin:0;
    }
    .image{
        position:absolute;
        opacity:0.12;
        right:40px;
    }
}
#contact{
    
    div:first-child{
        box-sizing:border-box;
        padding-right:40px;
    }
    .contactgegevens{
        display:grid;
        grid-gap:24px;

        ul li{
            list-style-type:none;
            line-height: 2;
            font-size:1rem;
        }
    }
  
}
#footer{
    margin-bottom:0;
    padding:96px;
    
    h2{
        margin-bottom:0;
    }

}


/* -- Container (for demo)
---------------------------------------------------------*/
.grid-container {
    display: grid;
    box-sizing:border-box;
    grid-gap:40px;
    padding:96px;
    margin-bottom:80px;
    
    &--01grid{
        grid-template-columns: auto;
    }
    &--02grid{
        grid-template-columns: 50% 50%;
    }
    &--03grid{
        grid-template-columns:1fr 1fr 1fr;
    }
    &--04grid{
        grid-template-columns: auto auto auto auto;
    }
    &--05grid{
        grid-template-columns: auto auto auto auto auto;
    }
    &--contact{
        grid-template-columns: 50% 25% 25%;
    }

    .grid-column{
        &--full{
            grid-column-start: 1;
            grid-column-end: 4;
        }
    }
        .header{
            grid-area: "header";
            column-gap:0;
            margin-top:1rem;
        }
        .main{
            grid-area: "main";
        }
        .footer{
            grid-area: "footer";
        }
    .headline{
        margin:1rem 0;
    }

}

.campaign-dialog{
    &__header{
        height:64px;
        padding:24px;
        box-sizing:border-box;
    }
    &__steps{   
        &__container{
            height:64px;
        }
        
        &__item{
            &__done{
                background-color:$clr-primary;
            }
        }
    }
    
}



/* -- Cards
---------------------------------------------------------*/
.card{
    border-radius:8px;
    padding:24px;
}
.divider{
    border-bottom:solid 1px $clr-misc-divider;
}
.elevation{
    background:$clr-misc-white;

        &--00dp{
            @include box_shadow(0);
            background:rgba($clr-misc-white,0);
        }
        &--01dp{
            @include box_shadow(1);
        }
        &--02dp{
            @include box_shadow(2);
        }
        &--03dp{
            @include box_shadow(3);
        }
        &--04dp{
            @include box_shadow(4);
        }
        &--05dp{
            @include box_shadow(5);
        }
}

.typography{
    line-height: $lh-base;
    letter-spacing:$ls-base;

	&--right {
		text-align:right;
    }
    &--left {
		text-align:left;
    }
    &--center {
		text-align:center;
	}
	&--primary {
		color:$clr-misc-primary;
    }
    &--secondary {
		color:$clr-misc-secondary;
    }
    &__h1 {
        font-size:$fs-h1;
        font-weight:bold;
        margin-bottom:16px;
    }
    &__h2 {
        font-size:$fs-h2;
        font-weight:$fw-bold;
        margin-bottom:16px;
    }
    &__h3 {
        font-size:$fs-h3;
        font-weight:$fw-bold;
        margin-bottom:16px;
    }
    &__h4 {
        font-size:$fs-h4;
        font-weight:$fw-bold;
    }
    &__h5 {
        font-size:$fs-h5;
        font-weight:$fw-bold;
        &--filled {
            background: green;
        }
    }
    &__subtitle1 {
        font-size:$fs-large;
        letter-spacing:0.01rem;
        margin-bottom:40px;
        text-transform:uppercase;
    }
    &__subtitle2 {
        font-size:$fs-milli;
    }
    &__body1{
        font-size:$fs-large;
    }
    &__body2{
        font-size:$fs-default;
    }
    &__caption{
        font-size:$fs-micro;
    }
    &__overline{
        font-size:$fs-micro;
        letter-spacing:0.0125rem;
        text-transform:uppercase;
    }
}

.palette{
    padding:12px;
    box-sizing:border-box;
    margin:8px 0;

    &--primary{
        background-color:$clr-primary;
        color:$clr-primary-contrast;

        &--dark{
            background-color:$clr-primary-dark;
            color:$clr-primary-contrast;
        }
        &--light{
            color:$clr-misc-primary;
            background-color:$clr-primary-light;
        }
        &--contrast{
            color:$clr-misc-primary;
            background-color:$clr-primary-contrast;
        }
        &--background{
            color:$clr-misc-primary;
            background-color:$clr-primary-background;
        }
    }
    &--secondary{
        background-color:$clr-secondary;
        color:$clr-primary-contrast;

        &--dark{
            background-color:$clr-secondary-dark;
            color:$clr-primary-contrast;
        }
        &--light{
            background-color:$clr-secondary-light;
            color:$clr-misc-primary;
            
        }
        &--contrast{
            color:$clr-misc-primary;
            background-color:$clr-secondary-contrast;
        }
        &--background{
            color:$clr-misc-primary;
            background-color:$clr-secondary-background;
        }
    }
    &--miscellaneous{
        &--primary{
            background-color:$clr-misc-primary;
            color: $clr-misc-white;
        }
        &--secondary{
            background-color:$clr-misc-secondary;
            color: $clr-misc-white;   
        }
        &--background{
            color:$clr-misc-primary;
            background-color:$clr-misc-background;
        }
        &--divider{
            color:$clr-misc-primary;
            background-color:$clr-misc-divider;
        }
    }
    &--warning{
        background-color:$clr-warning;
        color:$clr-primary-contrast;

        &--dark{
            background-color:$clr-warning-dark;
            color:$clr-primary-contrast;
        }
        &--light{
            background-color:$clr-warning-light;
            color:$clr-misc-primary;
            
        }
        &--contrast{
            color:$clr-misc-primary;
            background-color:$clr-warning-contrast;
        }
        &--background{
            color:$clr-misc-primary;
            background-color:$clr-warning-background;
        }
    }
    &--success{
        background-color:$clr-success;
        color:$clr-success-contrast;

        &--dark{
            background-color:$clr-success-dark;
            color:$clr-success-contrast;
        }
        &--light{
            background-color:$clr-success-light;
            color:$clr-misc-primary; 
        }
        &--contrast{
            color:$clr-misc-primary;
            background-color:$clr-success-contrast;
        }
        &--background{
            color:$clr-misc-primary;
            background-color:$clr-success-background;
        }
    }

    &--info{
        background-color:$clr-info;
        color:$clr-info-contrast;

        &--dark{
            background-color:$clr-info-dark;
            color:$clr-info-contrast;
        }
        &--light{
            background-color:$clr-info-light;
            color:$clr-misc-primary; 
        }
        &--contrast{
            color:$clr-misc-primary;
            background-color:$clr-info-contrast;
        }
        &--background{
            color:$clr-misc-primary;
            background-color:$clr-info-background;
        }
    }

    &--error{
        background-color:$clr-error;
        color:$clr-error-contrast;

        &--dark{
            background-color:$clr-error-dark;
            color:$clr-error-contrast;
        }
        &--light{
            background-color:$clr-error-light;
            color:$clr-misc-primary; 
        }
        &--contrast{
            color:$clr-misc-primary;
            background-color:$clr-error-contrast;
        }
        &--background{
            color:$clr-misc-primary;
            background-color:$clr-error-background;
        }
    }

    &--grey{
        background-color:$clr-grey;
        color:$clr-misc-white;

        &--800{
            background-color:$clr-grey-800;
            color:$clr-misc-white;
        }
        &--700{
            background-color:$clr-grey-700;
            color:$clr-misc-white;
        }
        &--600{
            background-color:$clr-grey-600;
            color:$clr-misc-white;
        }
        &--500{
            background-color:$clr-grey-500;
            color:$clr-misc-white;
        }
        &--400{
            background-color:$clr-grey-400;
            color:$clr-misc-white;
        }
        &--300{
            background-color:$clr-grey-300;
            color:$clr-misc-primary;
        }
        &--200{
            background-color:$clr-grey-200;
            color:$clr-misc-primary;
        }
        &--100{
            background-color:$clr-grey-100;
            color:$clr-misc-primary;
        }

    }
}


/* -- Button
---------------------------------------------------------*/
.button{
    display:inline-block;
        text-decoration:none;
        background-color:$clr-primary;
        
        border-radius:12px;
        margin-top:40px;
        @include box_shadow (2);
        transition:box-shadow 0.2s ease-in 0s;

    &--large{
        font-size:1.125rem;   
        padding:12px 40px;
    }
    &:hover{
        @include box_shadow (5);
    }
}




/* -- Cards
---------------------------------------------------------*/
.title_bar{
    
  grid-row-start: 1;
  grid-row-end: 4;
}







/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 1100px) {
    .grid-container {
        grid-gap:24px;
    }
    .title_bar{
        grid-row-start:1;
        grid-row-end:2;
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .grid-container--03grid{
        grid-template-columns:1fr 1fr;
    }
    
    .grid-container--02grid{
        grid-template-columns:auto;
    }
    #banner .images {
        display:none;
    }
}



@media screen and (max-width: 1000px) {
    .grid
    #banner .images {
        width:100%;
        height:1px;
        position:static;

        #meet_miek{
            z-index: 1;
            position: absolute;
            bottom:-160px;
            left:500px;
            display:none;
        }
    } 
}

@media screen and (max-width: 780px) {
    .title_bar{
        grid-row-start: initial; 
        grid-row-end: initial; 
        grid-column-start: initial; 
        grid-column-end: initial;
    }
    .grid-container--03grid{
        grid-template-columns:1fr;
    }
}

@media screen and (max-width: 580px) {
    #banner{
        padding-bottom:96px;
    }
    .grid-container{
        padding:40px;
        margin-bottom:0;
    }

}