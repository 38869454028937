/**
 * Design: https://marvelapp.com/prototype/5edj454/screen/73227909
 * Example usage:
 *
 * .card {
 *     @include box_shadow(1);

 *     &:hover {
 *         @include box_shadow(3);
 *     }
 * }
 *
 */


 @mixin box_shadow ($level) {
    @if $level == 0 {
        border: 1px solid #E0E0E0; //no shadow when element is inside level.
    } 
    @if $level == 1 {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.2);
    } 
    @else if $level == 2 {
        box-shadow: 0 8px 8px 1px rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.2);
    } 
    @else if $level == 3 {
        box-shadow: 0 8px 8px 1px rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.2);
    } 
    @else if $level == 4 {
        box-shadow: 0 8px 8px 1px rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.2);
    } 
    @else if $level == 5 {
        box-shadow: 0 8px 8px 1px rgba(0, 0, 0, 0.16), 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.2);
    }

}

